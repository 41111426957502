"use strict";

var win = parent ? parent.window : window;


/*
** Coockie based language redirection
 */
( function( window, document ) {

    /**
     * Helper to check if current url contains given address
     */
    function hrefContains( address ) {
        return document.location.href.indexOf( address ) !== -1;
    }

    window.cozify = {
        /**
         * Helper to read named cookie
         */
        readCookie: function( name ) {
            var nameEQ = escape( name ) + "=";
            var ca = document.cookie.split( ';' );
            for ( var i = 0; i < ca.length; i++ ) {
                var c = ca[i];
                while ( c.charAt( 0 ) === ' ' ) {
                    c = c.substring(1, c.length);
                }
                if ( c.indexOf( nameEQ ) === 0 ) {
                    return unescape(c.substring(nameEQ.length, c.length));
                }
            }
            return null;
        },

         /**
         * Helpers to create named cookie
         */
        createCookie: function ( name, value, days ) {
            var expires;
            if ( days ) {
                var date = new Date();
                date.setTime( date.getTime() + (days * 24 * 60 * 60 * 1000) );
                expires = "; expires=" + date.toGMTString();
            } else {
                expires = "";
            }
            document.cookie = escape( name ) + "=" + escape( value ) + expires + "; domain=.cozify.fi;path=/";
        },

        /**
         * Helper to erase named cookie
         */
        eraseCookie: function ( name ) {
            cozify.createCookie( name, "", -1 );
        },
        // addresses
        isWebFi: function() { return document.querySelector('#modalSiteMenu a').innerHTML === 'Kauppa'},
        isWebEn:  function() { return document.querySelector('#modalSiteMenu a').innerHTML === 'Shop'},
        isBlogFi: hrefContains( 'blogi.cozify.fi' ),
        isBlogEn: hrefContains('blog.cozify.fi'),
        isForumFi: hrefContains( 'foorumi.html' ),
        //isForumFi: function() { return hrefContains( 'forum.cozify.fi' ) && document.querySelector('.MLabel') && document.querySelector('.MLabel').innerHTML === "Uusin:" },
        isForumEn: hrefContains( 'forum.html' ),
        //isForumEn: function() { return hrefContains( 'forum.cozify.fi' ) && document.querySelector('.MLabel') && document.querySelector('.MLabel').innerHTML === "Most recent:" },
        isShopFi: hrefContains( 'kauppa.cozify.fi' ),
        //isShopFi: document.querySelector(".ly-languages-switcher-link.current_lang").innerHTML === "Suomi"
        isShopEn: hrefContains( 'shop.cozify.fi' ),
        //isShopEn: document.querySelector(".ly-languages-switcher-link.current_lang").innerHTML === "English"
        webHomeFi:  '//kauppa.cozify.fi/',
        webHomeEn:  '//shop.cozify.fi/',
        webForumFi:  '//cozify.fi/foorumi.html',
        webForumEn:  '//cozify.fi/forum.html',
        shopHomeFi:  '//kauppa.cozify.fi',
        shopHomeEn:  '//shop.cozify.fi',
        blogHomeFi:  '//blogi.cozify.fi',
        blogHomeEn:  '//blog.cozify.fi',
        forumHomeFi:  '//forum.cozify.fi',
        forumHomeEn:  '//forum.cozify.fi'

    }
    /*
    ** Read language cookie and make required redirects
     */
    var language = cozify.readCookie( 'language' );

    if ( language ) {
        // Add here more page/language rules if needed...
        //
        // If Englis cookie in Finnish page:
        if ( language === 'en' ) {
            // Web page
            if ( cozify.isWebFi ) {
                document.location = cozify.webHomeEn;
            }
            // Blog
            else if ( cozify.isBlogFi ) {
                document.location = cozify.blogHomeEn;
            }
            // Forum
            else if ( cozify.isForumFi ) {
                document.location = cozify.webForumEn;
            }
            // Shop
            else if ( cozify.isShopFi ) {
                document.location = cozify.shopHomeEn;
            }
        // If Finnish cookie in English page:
        } else if ( language === 'fi' ) {
            // Web page
            if ( cozify.isWebEn ) {
                document.location = cozify.webHomeFi;
            }
            // Blog
            else if ( cozify.isBlogEn ) {
                document.location = cozify.blogHomeFi;
            }
            // Forum
            else if ( cozify.isForumEn ) {
                document.location = cozify.webForumFi;
            }
            // Shop
            else if ( cozify.isShopEn ) {
                document.location = cozify.shopHomeFi;
            }
        }
    }


})( win, win.document);

